import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'
import { getStoredIsHighContrastMode } from './localStorage'
// + generateEmojiGrid(guesses)
// ${solutionIndex}
export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  url: any,
  date: any,
  shareable_link: any
) => {
  navigator.clipboard.writeText(
    `${url.charAt(0).toUpperCase() + url.slice(1)}, ${date}, ${
      lost ? 'X' : guesses.length
    }/6${isHardMode ? '*' : ''}\n\n` +
      generateEmojiGrid(guesses) +
      `${shareable_link ? '\n\n' + shareable_link : ''}`
  )
  
  
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
     
      
      const status = getGuessStatuses(guess)
      // console.log("status",status);
      
      const isHighContrast = getStoredIsHighContrastMode()
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              if (isHighContrast) {
                return '🟦'
              }
              return '🟦'
            case 'present':
              if (isHighContrast) {
                return '🟨'
              }
              return '🟨'
            default:
              if (localStorage.getItem('theme') === 'dark') {
                return '⬛'
              }
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
