import React, { useEffect, useState } from "react";
import $ from 'jquery';
export default function StartPage({
  paramValue,
  sponsorshipModule,
  headerTitle,
  windowHeight,
  wordData,
  clientName,
  setIspaly,
  setIsInfoModalOpen,
  isAddShow,
  setShowLoader
}) {
  
  function setWelcomePage() {
    // // setShowLoader(true);
    // window.location.reload();
    setIspaly(true);
    if (localStorage.getItem("isReload") == "done") {
      if (!localStorage.getItem("inModel")) {
        setIsInfoModalOpen(true);
        localStorage.setItem("inModel", "done");
      }

      localStorage.setItem("puzzle_id", btoa(wordData.id));
    }
    localStorage.setItem("welcomePage" , 'true');
    
  }



  let divideHeight = "";
  if (
    sponsorshipModule == true &&
    (paramValue === "null" || paramValue == "null" || !paramValue)
  ) {
    divideHeight = 120;
  } else {
    divideHeight = 59.5;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice =
      /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );
    setIsMobile(isMobileDevice);
  }, []);

  let addDivHeight = 0;
  if(isMobile && isAddShow && (paramValue === 'null' || paramValue == 'null' ||!paramValue ) ){
   addDivHeight = 90;
  }
  
  return (
    <div>
      <div
        className="startup_page_main mx-width"
        style={{ height: `${windowHeight - divideHeight - addDivHeight}px` }}
      >
        <div className="welcome">
          <div className="flex justify-center">
            <img style={{ height: "80px" }} src="/wave.png" />
          </div>
          <div className="welcome-text">Welcome!</div>
        </div>
        <div className="welcome-heading">
          <div className="welcome-heading-text">
            <p> You have 6 tries to guess a word connected to news from</p>
          </div>
          <div className="customer-name">
            <p>{clientName}.</p>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="play-button">
          <button
            onClick={setWelcomePage}
            className="btn btn-dark play-btn"
            id="showModel2"
          >
            Play
          </button>
        </div>
      </div>
      {isMobile && isAddShow && (paramValue === 'null' || paramValue == 'null' ||!paramValue ) ? (
          <div className="ad-head">
            <div className="ad-text" >ADVERTISEMENT</div>
          <div className="ads-mob-play-screen ads-dev"></div>
          </div>
        ) : null}
    </div>
  );
}
